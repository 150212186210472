import { LoadableLazy } from '@confluence/loadable';

import { pgRestrictionsI18NMessages } from './ui/components/PageRestrictionsShowNotTell';

export const PageInsightsShowNotTellLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageInsightsShowNotTell" */ './ui/components/PageInsightsShowNotTell'
			)
		).PageInsightsShowNotTell,
});

export const PageRestrictionsShowNotTellLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageRestrictionsShowNotTell" */ './ui/components/PageRestrictionsShowNotTell'
			)
		).PageRestrictionsShowNotTell,
});
export { pgRestrictionsI18NMessages };

export const PublicSharableLinkShowNotTellLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PublicSharableLinkShowNotTell" */ './ui/components/PublicSharableLinkShowNotTell'
			)
		).PublicSharableLinkShowNotTell,
});

export const SpacePermissionsShowNotTellLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShowNotTellExperiment" */ './ui/components/SpacePermissionsShowNotTell'
			)
		).SpacePermissionsShowNotTell,
});

// Allows us to check for the experiment variant in packages outside of the HOC
export { useShowNotTellFGABExperiment } from './hooks/useShowNotTellFGExperiment';
export { showNotTellFeatureGates } from './utils/experiment-config';
