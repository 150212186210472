import React, { useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import type { ActionType } from '@atlaskit/flag/types';
import { Box, xcss } from '@atlaskit/primitives';

import type { DialogsStateContainer } from '@confluence/dialogs';
import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import type { FlagsStateContainer } from '@confluence/flags';

import { useIsExternalCollaborator } from '../../entry-points/useIsExternalCollaborator';
import { useGuestRequestToUpgradeStatus } from '../../entry-points/useGuestRequestToUpgradeStatus';

import { GuestRequestUpgradeButton } from './GuestRequestUpgradeButton';
import { GuestRequestToUpgradeLearnMoreDialogModal } from './GuestRequestToUpgradeLearnMoreModal';
import { useSubmitGuestUpgradeRequest } from './useSubmitGuestUpgradeRequest';
import { UpgradeStatus } from './types';

const TRACK_EVENT = 'sendTrackEvent';
const GUEST_UPGRADE_FLAG_SOURCE = 'guestRequestAccess';
const LEARN_MORE_MODAL = 'guestUpgradeRequestLearnMore';

export const i18n = defineMessages({
	learnMoreButton: {
		id: 'external-collab-ui.guest-request-to-upgrade-flag.learn-more-button',
		defaultMessage: 'Learn more',
		description: 'Button text to learn more about how to upgrade from a guest to licensed user',
	},
	requestSentFlagTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-flag.request-sent-title',
		defaultMessage: 'Request sent',
		description:
			'Flag notification title informing the guest that their request to be upgraded to a licensed user was successfully submitted',
	},
	requestSentFlagDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade-flag.request-sent-description',
		defaultMessage: 'You’ll get a confirmation email from your admin if approved.',
		description:
			'Flag notification description informing guest that request was submitted and what they should expect',
	},
	errorFlagTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-flag.error-title',
		defaultMessage: 'Request not sent',
		description: 'Flag notification title informing guest that request encountered an error',
	},
	errorFlagDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade-flag.error-description',
		defaultMessage: 'Refresh the page to resubmit your request, or try again later.',
		description: 'Flag notification description informing guest that request encountered an error',
	},
});

const requestButtonContainerStyles = xcss({
	marginLeft: 'space.negative.150',
	marginTop: 'space.negative.025',
});

const learnMoreButtonContainerStyles = xcss({
	marginTop: 'space.negative.025',
});

type GuestRequestToUpgradeNotificationsWrapperProps = {
	children: React.ReactNode;
	dialogs: DialogsStateContainer;
	flags: FlagsStateContainer;
	experienceTrackerName: string;
	flagTitle: string;
	requestUpgradeFlagDescription?: string;
	requestPendingFlagDescription?: string;
	touchpoint?: string;
	flagActionSubjectId: string;
	showFlag: boolean;
	updateFlagState?: (flagState: boolean) => void;
};

export const GuestRequestToUpgradeNotificationsWrapper = ({
	children,
	dialogs,
	flags,
	showFlag = false,
	experienceTrackerName,
	flagTitle,
	requestUpgradeFlagDescription,
	requestPendingFlagDescription,
	touchpoint,
	flagActionSubjectId,
	updateFlagState,
}: GuestRequestToUpgradeNotificationsWrapperProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const [flagNeedsUpdate, setFlagNeedsUpdate] = useState<boolean>(false);
	const { isExternalCollaborator: isGuest } = useIsExternalCollaborator();
	const meetsEnrollmentCriteria = showFlag && isGuest;

	const handleFlagClose = useCallback(() => {
		void flags.hideFlag(flagActionSubjectId);

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'dismissed',
				actionSubject: 'flag',
				actionSubjectId: flagActionSubjectId,
				source: GUEST_UPGRADE_FLAG_SOURCE,
				attributes: {
					touchpointId: touchpoint,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, flags, flagActionSubjectId, touchpoint]);

	const showErrorFlag = useCallback(
		(submissionError: Error) => {
			void flags.showErrorFlag({
				title: <FormattedMessage {...i18n.errorFlagTitle} />,
				description: <FormattedMessage {...i18n.errorFlagDescription} />,
			});
			experienceTracker.fail({
				name: experienceTrackerName,
				error: submissionError,
			});
		},
		[experienceTracker, flags, experienceTrackerName],
	);

	const onGuestUpgradeRequestSubmitted = useCallback(() => {
		void flags.hideAllFlags();
		void flags.showSuccessFlag({
			title: <FormattedMessage {...i18n.requestSentFlagTitle} />,
			description: <FormattedMessage {...i18n.requestSentFlagDescription} />,
		});
		experienceTracker.succeed({
			name: experienceTrackerName,
		});
	}, [experienceTracker, flags, experienceTrackerName]);

	const handleOnSubmit = useCallback(
		(error?: Error, analyticsSource?: string) => {
			// source differentiates whether request was submitted directly from the flag or from the learn more modal
			createAnalyticsEvent({
				type: TRACK_EVENT,
				data: {
					action: !!error ? 'failed' : 'requested',
					actionSubject: 'productAccess',
					source: analyticsSource ?? GUEST_UPGRADE_FLAG_SOURCE,
					attributes: {
						touchpointId: touchpoint,
					},
				},
			}).fire();

			if (!error) {
				onGuestUpgradeRequestSubmitted();
			} else {
				showErrorFlag(error);
			}
		},
		[createAnalyticsEvent, showErrorFlag, onGuestUpgradeRequestSubmitted, touchpoint],
	);

	const [
		submitGuestUpgradeRequest,
		{ loading: guestUpgradeRequestIsLoading, isSubmitted: guestUpgradeSubmitted },
	] = useSubmitGuestUpgradeRequest({ onCompleted: handleOnSubmit });

	const { upgradeStatus, loading: permissionLoading } =
		useGuestRequestToUpgradeStatus(!meetsEnrollmentCriteria);
	const permissionDenied = upgradeStatus === UpgradeStatus.NOT_ELIGIBLE_TO_UPGRADE_AT_ALL;

	const requestPending =
		guestUpgradeSubmitted || upgradeStatus === UpgradeStatus.AN_UPGRADE_REQUEST_IS_PENDING;

	const submitGuestUpgradeRequestWithAnalytics = useCallback(
		(_: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			setFlagNeedsUpdate(true);
			const analyticsAttributes = {
				...analyticsEvent.payload.attributes,
				touchpointId: touchpoint,
			};
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					...analyticsEvent.payload,
					actionSubjectId: 'submitGuestUpgradeRequest',
					source: GUEST_UPGRADE_FLAG_SOURCE,
					attributes: analyticsAttributes,
				},
			}).fire();
			experienceTracker.start({
				name: experienceTrackerName,
			});
			void submitGuestUpgradeRequest();
		},
		[
			createAnalyticsEvent,
			experienceTracker,
			submitGuestUpgradeRequest,
			touchpoint,
			experienceTrackerName,
		],
	);

	const openLearnMoreModal = useCallback(
		(_: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			void flags.hideAllFlags();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					...analyticsEvent.payload,
					actionSubjectId: LEARN_MORE_MODAL,
					source: GUEST_UPGRADE_FLAG_SOURCE,
					attributes: {
						requested: requestPending,
						touchpointId: touchpoint,
					},
				},
			}).fire();
			dialogs.showModal(GuestRequestToUpgradeLearnMoreDialogModal, {
				onSubmit: (error?: Error | undefined) => handleOnSubmit(error, LEARN_MORE_MODAL),
				requestPending,
				analytics: {
					touchpointId: touchpoint,
				},
			});
		},
		[createAnalyticsEvent, dialogs, handleOnSubmit, requestPending, touchpoint, flags],
	);

	useEffect(() => {
		if (permissionLoading || upgradeStatus === undefined || !meetsEnrollmentCriteria) {
			return;
		}
		let actions: ActionType[] = [];
		if (!permissionDenied) {
			actions = [
				{
					content: (
						<Box xcss={requestButtonContainerStyles}>
							<GuestRequestUpgradeButton
								appearance="link"
								requestPending={requestPending}
								loading={guestUpgradeRequestIsLoading}
								onClick={submitGuestUpgradeRequestWithAnalytics}
							/>
						</Box>
					),
				},
				{
					content: (
						<Box xcss={learnMoreButtonContainerStyles}>
							<Button appearance="link" onClick={openLearnMoreModal}>
								<FormattedMessage {...i18n.learnMoreButton} />
							</Button>
						</Box>
					),
				},
			];
		}

		const displayedFlagIds = flags.state.flags.map((f) => f.id);
		if (
			showFlag &&
			(flags.state.flags.length === 0 || !displayedFlagIds.includes(flagActionSubjectId))
		) {
			let flagDescription = requestUpgradeFlagDescription;
			if (permissionDenied) {
				flagDescription = '';
			} else if (requestPending) {
				flagDescription = requestPendingFlagDescription;
			}

			void flags.showCustomFlag({
				id: flagActionSubjectId,
				title: flagTitle,
				description: flagDescription,
				isAutoDismiss: false,
				onClose: handleFlagClose,
				actions,
			});
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'shown',
					actionSubject: 'flag',
					actionSubjectId: flagActionSubjectId,
					source: GUEST_UPGRADE_FLAG_SOURCE,
					attributes: {
						touchpointId: touchpoint,
						upgradeStatus,
					},
				},
			}).fire();

			// if guest can request upgrade, then they will see a button on the flag to request upgrade
			// if request is pending, button will be disabled, so this analytics should not fire
			// if request is denied, then the flag will not have a button to request upgrade
			if (upgradeStatus === UpgradeStatus.CAN_REQUEST) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'shown',
						actionSubject: 'button',
						actionSubjectId: 'submitGuestUpgradeRequest',
						source: GUEST_UPGRADE_FLAG_SOURCE,
						attributes: {
							touchpointId: touchpoint,
						},
					},
				}).fire();
			}
		} else if (flagNeedsUpdate && guestUpgradeRequestIsLoading && flags.state.flags.length > 0) {
			void flags.updateFlag(flagActionSubjectId, {
				actions,
			});
			setFlagNeedsUpdate(false);
		}

		// resets the flag state to false to prevent flag.showCustomFlag() from re-triggering
		// for the scenarios when multiple experiments are showing the flag
		// the user performing any flag-related action (i.e close flag, request upgrade, open modal) will prevent the flag from re-displaying
		updateFlagState?.(false);
	}, [
		createAnalyticsEvent,
		flagActionSubjectId,
		flags,
		flagTitle,
		flagNeedsUpdate,
		guestUpgradeRequestIsLoading,
		handleFlagClose,
		meetsEnrollmentCriteria,
		openLearnMoreModal,
		permissionDenied,
		permissionLoading,
		requestPending,
		requestUpgradeFlagDescription,
		requestPendingFlagDescription,
		showFlag,
		submitGuestUpgradeRequestWithAnalytics,
		touchpoint,
		upgradeStatus,
		updateFlagState,
	]);

	return <>{children}</>;
};
